import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/LayoutPost.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div {...{
      "className": "hero"
    }}>{`
  `}<span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "992px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4185b1ebfb32dd0a59b074fec92ef081/94f1b/terminal.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.451612903225815%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/svg+xml,%3csvg%20xmlns=\\'http://www.w3.org/2000/svg\\'%20width=\\'400\\'%20height=\\'225\\'%20viewBox=\\'0%200%20400%20225\\'%20preserveAspectRatio=\\'none\\'%3e%3cpath%20d=\\'M0%2033l1%2034%204-1c3%200%205%200%206-2l4-3c3-1%2013%203%2020%207%205%203%205%203%2010%202%204-1%204-1%207%201%203%204%206%205%209%205l7%201c3%202%204%202%209%200%208-2%2019-3%2021-2%202%202%204%201%207-5%203-5%206-7%2010-7l3-2c2-2%205-3%208-2s4%201%208-1l6-3c3%200%207-4%207-8l3-6%204-5c2-4%2012-3%2016%200%204%204%206%203%207-5%200-7%203-9%2011-11l8-2c4%200%2013-4%2016-6l4-3%209-1c7-1%2010-2%2012-1l5%201%205%202%205%201%207%202c3%202%207%203%2011%201%203%200%208%203%2010%206l9%203h4c3%201%206%200%2015-7l7-2%207-2c3-2%204-2%205-1%203%202%204%201%207-1l5-2c4%200%208-2%2013-6l3-2H0v33m176%2017l-18%204%2019%205%2021%205%2021-5%2022-4c2-1-38-8-43-8l-22%203M93%2099l-63%2014%2040%2023a48577%2048577%200%2001123%2066%209880%209880%200%2000175-87L243%2084l-1%204-1%203c-1-1-15%201-21%203a370%20370%200%2001-22%205c0-2-1-3-17-7l-18-3c-6%200-7-1-8-3%200-2-2-1-62%2013\\'%20fill=\\'%23d3d3d3\\'%20fill-rule=\\'evenodd\\'/%3e%3c/svg%3e')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4185b1ebfb32dd0a59b074fec92ef081/c4d1c/terminal.webp 248w", "/static/4185b1ebfb32dd0a59b074fec92ef081/488b2/terminal.webp 496w", "/static/4185b1ebfb32dd0a59b074fec92ef081/f4b34/terminal.webp 992w", "/static/4185b1ebfb32dd0a59b074fec92ef081/47a9f/terminal.webp 1280w"],
              "sizes": "(max-width: 992px) 100vw, 992px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/4185b1ebfb32dd0a59b074fec92ef081/577a4/terminal.png 248w", "/static/4185b1ebfb32dd0a59b074fec92ef081/21d86/terminal.png 496w", "/static/4185b1ebfb32dd0a59b074fec92ef081/c9b7e/terminal.png 992w", "/static/4185b1ebfb32dd0a59b074fec92ef081/94f1b/terminal.png 1280w"],
              "sizes": "(max-width: 992px) 100vw, 992px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/4185b1ebfb32dd0a59b074fec92ef081/c9b7e/terminal.png",
              "alt": "test",
              "title": "test",
              "loading": "lazy",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      